@font-face
  font-family: 'LeagueGothicRegular'
  src: url('../fonts/league-gothic/LeagueGothic-Regular-webfont.eot')
  src: url('../fonts/league-gothic/LeagueGothic-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/league-gothic/LeagueGothic-Regular-webfont.woff') format('woff'), url('../fonts/league-gothic/LeagueGothic-Regular-webfont.ttf') format('truetype'), url('../fonts/league-gothic/LeagueGothic-Regular-webfont.svg#LeagueGothicRegular') format('svg')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'PermanentMarker Regular'
  src: url('../fonts/permanent-marker/PermanentMarker-webfont.eot')
  src: url('../fonts/permanent-marker/PermanentMarker-webfont.eot?#iefix') format("embedded-opentype"), url('../fonts/permanent-marker/PermanentMarker-webfont.woff') format("woff"), url('../fonts/permanent-marker/PermanentMarker-webfont.ttf') format("truetype"), url('../fonts/permanent-marker/PermanentMarker-webfont.svg#PermanentMarkerRegular') format("svg")
  font-weight: normal
  font-style: normal
