
.item.post_it {
  width: 222px;
  height: 190px;

  -webkit-box-shadow: 1px 1px 6px rgba(0,0,0,0.4), inset 0px 1px 0px rgba(255,255,255,0.4);
     -moz-box-shadow: 1px 1px 6px rgba(0,0,0,0.4), inset 0px 1px 0px rgba(255,255,255,0.4);
          box-shadow: 1px 1px 6px rgba(0,0,0,0.4), inset 0px 1px 0px rgba(255,255,255,0.4);
}


/*
 * Gradients code generated by
 * http://www.colorzilla.com/gradient-editor/
 * (use import function to change existing gradients)
 */

.item.post_it.blue {
  background-color: rgb(155,189,239); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-moz-linear-gradient(top,  rgb(121,163,221) 0%, rgb(155,189,239) 15%, rgb(155,189,239) 82%, rgb(121,163,221) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(121,163,221)), color-stop(15%,rgb(155,189,239)), color-stop(82%,rgb(155,189,239)), color-stop(100%,rgb(121,163,221))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(121,163,221) 0%,rgb(155,189,239) 15%,rgb(155,189,239) 82%,rgb(121,163,221) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-o-linear-gradient(top,  rgb(121,163,221) 0%,rgb(155,189,239) 15%,rgb(155,189,239) 82%,rgb(121,163,221) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-ms-linear-gradient(top,  rgb(121,163,221) 0%,rgb(155,189,239) 15%,rgb(155,189,239) 82%,rgb(121,163,221) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						linear-gradient(top,  rgb(121,163,221) 0%,rgb(155,189,239) 15%,rgb(155,189,239) 82%,rgb(121,163,221) 100%); /* W3C */
}

.item.post_it.green {
  background-color: rgb(125,227,134); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-moz-linear-gradient(top,  rgb(98,208,106) 0%, rgb(125,227,134) 15%, rgb(125,227,134) 82%, rgb(98,208,106) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(98,208,106)), color-stop(15%,rgb(125,227,134)), color-stop(82%,rgb(125,227,134)), color-stop(100%,rgb(98,208,106))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(98,208,106) 0%,rgb(125,227,134) 15%,rgb(125,227,134) 82%,rgb(98,208,106) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-o-linear-gradient(top,  rgb(98,208,106) 0%,rgb(125,227,134) 15%,rgb(125,227,134) 82%,rgb(98,208,106) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-ms-linear-gradient(top,  rgb(98,208,106) 0%,rgb(125,227,134) 15%,rgb(125,227,134) 82%,rgb(98,208,106) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						linear-gradient(top,  rgb(98,208,106) 0%,rgb(125,227,134) 15%,rgb(125,227,134) 82%,rgb(98,208,106) 100%); /* W3C */
}

.item.post_it.pink {
  background-color: rgb(242,172,182); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-moz-linear-gradient(top,  rgb(225,135,150) 0%, rgb(242,172,182) 15%, rgb(242,172,182) 82%, rgb(225,135,150) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(225,135,150)), color-stop(15%,rgb(242,172,182)), color-stop(82%,rgb(242,172,182)), color-stop(100%,rgb(225,135,150))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(225,135,150) 0%,rgb(242,172,182) 15%,rgb(242,172,182) 82%,rgb(225,135,150) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-o-linear-gradient(top,  rgb(225,135,150) 0%,rgb(242,172,182) 15%,rgb(242,172,182) 82%,rgb(225,135,150) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-ms-linear-gradient(top,  rgb(225,135,150) 0%,rgb(242,172,182) 15%,rgb(242,172,182) 82%,rgb(225,135,150) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						linear-gradient(top,  rgb(225,135,150) 0%,rgb(242,172,182) 15%,rgb(242,172,182) 82%,rgb(225,135,150) 100%); /* W3C */
}

.item.post_it.purple {
  background-color: rgb(214,136,236); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-moz-linear-gradient(top,  rgb(190,106,218) 0%, rgb(214,136,236) 15%, rgb(214,136,236) 82%, rgb(190,106,218) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(190,106,218)), color-stop(15%,rgb(214,136,236)), color-stop(82%,rgb(214,136,236)), color-stop(100%,rgb(190,106,218))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(190,106,218) 0%,rgb(214,136,236) 15%,rgb(214,136,236) 82%,rgb(190,106,218) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-o-linear-gradient(top,  rgb(190,106,218) 0%,rgb(214,136,236) 15%,rgb(214,136,236) 82%,rgb(190,106,218) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-ms-linear-gradient(top,  rgb(190,106,218) 0%,rgb(214,136,236) 15%,rgb(214,136,236) 82%,rgb(190,106,218) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						linear-gradient(top,  rgb(190,106,218) 0%,rgb(214,136,236) 15%,rgb(214,136,236) 82%,rgb(190,106,218) 100%); /* W3C */
}

.item.post_it.yellow {
  background: rgb(242,237,171); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -moz-linear-gradient(top,  rgb(225,218,134) 0%, rgb(242,237,171) 15%, rgb(242,237,171) 82%, rgb(225,218,134) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(225,218,134)), color-stop(15%,rgb(242,237,171)), color-stop(82%,rgb(242,237,171)), color-stop(100%,rgb(225,218,134))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(225,218,134) 0%,rgb(242,237,171) 15%,rgb(242,237,171) 82%,rgb(225,218,134) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -o-linear-gradient(top,  rgb(225,218,134) 0%,rgb(242,237,171) 15%,rgb(242,237,171) 82%,rgb(225,218,134) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -ms-linear-gradient(top,  rgb(225,218,134) 0%,rgb(242,237,171) 15%,rgb(242,237,171) 82%,rgb(225,218,134) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              linear-gradient(top,  rgb(225,218,134) 0%,rgb(242,237,171) 15%,rgb(242,237,171) 82%,rgb(225,218,134) 100%); /* W3C */
}

.item.post_it.white {
  background: rgb(255,255,255); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -moz-linear-gradient(top,  rgb(229,229,229) 0%, rgb(255,255,255) 15%, rgb(255,255,255) 82%, rgb(229,229,229) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(229,229,229)), color-stop(15%,rgb(255,255,255)), color-stop(82%,rgb(255,255,255)), color-stop(100%,rgb(229,229,229))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(229,229,229) 0%,rgb(255,255,255) 15%,rgb(255,255,255) 82%,rgb(229,229,229) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -o-linear-gradient(top,  rgb(229,229,229) 0%,rgb(255,255,255) 15%,rgb(255,255,255) 82%,rgb(229,229,229) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -ms-linear-gradient(top,  rgb(229,229,229) 0%,rgb(255,255,255) 15%,rgb(255,255,255) 82%,rgb(229,229,229) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              linear-gradient(top,  rgb(229,229,229) 0%,rgb(255,255,255) 15%,rgb(255,255,255) 82%,rgb(229,229,229) 100%); /* W3C */
}

.item.post_it.orange {
  background: rgb(240,202,153); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -moz-linear-gradient(top,  rgb(216,181,137) 0%, rgb(240,202,153) 15%, rgb(240,202,153) 82%, rgb(216,181,137) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(216,181,137)), color-stop(15%,rgb(240,202,153)), color-stop(82%,rgb(240,202,153)), color-stop(100%,rgb(216,181,137))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(216,181,137) 0%,rgb(240,202,153) 15%,rgb(240,202,153) 82%,rgb(216,181,137) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -o-linear-gradient(top,  rgb(216,181,137) 0%,rgb(240,202,153) 15%,rgb(240,202,153) 82%,rgb(216,181,137) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -ms-linear-gradient(top,  rgb(216,181,137) 0%,rgb(240,202,153) 15%,rgb(240,202,153) 82%,rgb(216,181,137) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              linear-gradient(top,  rgb(216,181,137) 0%,rgb(240,202,153) 15%,rgb(240,202,153) 82%,rgb(216,181,137) 100%); /* W3C */
}

.item.post_it.gray {
  background: rgb(191,191,191); /* Old browsers */
  background: -moz-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-moz-linear-gradient(top,  rgb(171,171,171) 0%, rgb(191,191,191) 15%, rgb(191,191,191) 82%, rgb(171,171,171) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgb(171,171,171)), color-stop(15%,rgb(191,191,191)), color-stop(82%,rgb(191,191,191)), color-stop(100%,rgb(171,171,171))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
              -webkit-linear-gradient(top,  rgb(171,171,171) 0%,rgb(191,191,191) 15%,rgb(191,191,191) 82%,rgb(171,171,171) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-o-linear-gradient(top,  rgb(171,171,171) 0%,rgb(191,191,191) 15%,rgb(191,191,191) 82%,rgb(171,171,171) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						-ms-linear-gradient(top,  rgb(171,171,171) 0%,rgb(191,191,191) 15%,rgb(191,191,191) 82%,rgb(171,171,171) 100%); /* IE10+ */
  background: linear-gradient(68deg, rgba(255,255,255,0.5) 7%,rgba(255,255,255,0) 26%),
  						linear-gradient(top,  rgb(171,171,171) 0%,rgb(191,191,191) 15%,rgb(191,191,191) 82%,rgb(171,171,171) 100%); /* W3C */
}

/* Progress bar colors per note color. */
.item.post_it.blue .ui-progress {
	background-color: #1049A9;
}

.item.post_it.green .ui-progress {
	background-color: #008500;
}

.item.post_it.pink .ui-progress {
	background-color: #AD2B4E; /*#ef4d9;*/
}

.item.post_it.purple .ui-progress {
	background-color: #A101A6;
}

.item.post_it.yellow .ui-progress {
  background-color: #008000;
}

.item.post_it.white .ui-progress {
  background-color: #e5e5e5;
}

.item.post_it.orange .ui-progress {
  background-color: #d8b589;
}

.item.post_it.gray .ui-progress {
	background-color: #989898;
}


.item.post_it .shadow {
  position: absolute;
  left: -14px;
  bottom: -15px;
  width: 169px;
  height: 114px;
  background-repeat: no-repeat;
  opacity: 0.6;
}

/* Other UI items. */

.item.post_it .wrapper {
  position: absolute;
  left: 12px;
  top: 12px;
  right: 12px;
  bottom: 12px;
  z-index: 1010;

  overflow: hidden;
}

.item.post_it .data {
  width: 100%;
  height: 100%;

  border: none;
  padding: 0px;
  margin: 0px;
  background: transparent;

	/*position: relative;*/
	overflow-y: auto;
	overflow-x: hidden;

  /* Remove browser idiosyncrasies. */
  outline: none;
  resize: none;

  font-size: 13px;
  font-weight: normal;
  line-height: 16px;

  word-wrap: break-word;
  /*cursor: text;*/
  white-space: pre-wrap;

  z-index: 1010;
}

.item.post_it .antiscroll-scrollbar-vertical {
	right: 0px;
}

.item.post_it .antiscroll-scrollbar-horizontal {
	display: none;
}

/***********************************************************************
 CAUTION: Small changes to padding, margin and spacing here can
 have big effects on animation when adding and removing attachments,
 as well as when showing and hiding the progres bar. You've been warned!
***********************************************************************/

.item.post_it .progress {
	position: relative;
	/*left: 12px;
	right: 12px;*/
	bottom: 0px;
	padding-top: 8px;
	padding-bottom: 6px;
}

.item.post_it .attachments {
	position: absolute;
	left: 12px;
	right: 12px;
	bottom: 0px;

	padding-top: 4px;
	padding-bottom: 6px;

	overflow: visible;
}

.item.post_it .attachments .separator {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 19px;
	height: 0px;

	border-top: solid 1px #999;
	border-top: solid 1px rgba(0, 0, 0, 0.15);
}

.item.post_it .attachments .delete-attachments {
	position: absolute;
	top: -9px;
	right: -2px;
	width: 20px;
	height: 20px;
	background-position: center center;
	background-repeat: no-repeat;
	z-index: 1011;
	opacity: 0.4;
}

.item.post_it .attachments .delete-attachments.on {
	opacity: 0.8;
}

.item.post_it .attachments .delete-attachments:hover {
	opacity: 1;
	cursor: pointer;
}

.item.post_it .attachments .attachment {
	position: relative;
	display: block;
	cursor: pointer;
	font-size: 13px;
  font-weight: normal;
  text-decoration: none;

  white-space: nowrap;

  overflow: hidden;

  padding-top: 4px;
}

.item.post_it .attachments .attachment .image {
	width: 18px;
	height: 24px;
	display: inline-block;
	vertical-align: middle;
	background-position: center center;
	background-repeat: no-repeat;
	margin-right: 10px;
	margin-bottom: 3px; /* For text positioning. */

	border: solid 1px #999;
	border: solid 1px rgba(0,0,0,0.25);
}

.item.post_it .attachments .attachment .title {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	color: black;
	overflow: hidden;
	white-space: nowrap;

	width: 100%;

	text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

.item.post_it .attachments .attachment .title:hover {
	text-decoration: underline;
}

.item.post_it .attachments .attachment .remove {
	display: block;
  position: absolute;
  top: 6px;
  right: 1px;
  /*background-color: red;*/
  width: 24px;
  height: 24px;
  background-position: right center;
  background-repeat: no-repeat;
	opacity: 0.6;
}

.item.post_it .attachments .attachment .remove:hover {
	opacity: 1;
	cursor: pointer;
}