.home.blog

  .main
    color: #373737

    .block
      width: 600px

  .link-back
    text-align: center
    color: #9099a3
    font-size: 14px

    a
      color: #9099a3
      text-decoration: none
      font-weight: 300

      &:hover
        text-decoration: underline

  .post

    .post-head
      width: 100%
      height: 200px

    h2
      font-weight: normal
      font-size: 28px
      margin-bottom: 10px
      text-align: center


      a
        color: inherit
        text-decoration: none
        font-weight: 300

        &:hover
          text-decoration: underline

    h3
      font-weight: normal
      color: #000
      margin-top: 30px
      margin-bottom: 20px
      font-size: 18px
      font-weight: 300

    .meta
      font-size: 14px
      margin-top: 10px

    .content
      /*background: #FFF*/
      /* Bottom based off margin-bottom of p */
      padding: 5px 0px 6px 0px
      -webkit-border-radius: 3px
      -moz-border-radius: 3px
      -ms-border-radius: 3px
      border-radius: 3px
      font-size: 15px

      ul
        list-style-type: disc
        list-style-position: inside
        padding-left: 20px
        margin-bottom: 4px

        li
          padding-bottom: 10px

      p
        line-height: 24px
        margin-bottom: 14px
        font-size: 15px

      pre
        margin-bottom: 14px

  .post:not(:last-child)
    margin-bottom: 60px
    padding-bottom: 40px
    border-bottom: solid 5px #DDD

  .white-bg
    .post

      h2
        text-decoration: none
        color: #000

      p, li
        color: #9099a3

      .meta
        color: #373737

  .dark-blue-bg
    .post

      p, li
        color: #9099a3

      h2
        color: white

      h3
        color: white

      .meta
        color: #F5F5F1

      &:not(:last-child)
        margin-bottom: 50px
        border-bottom: solid 5px #9099a3

  .other-posts
    margin-bottom: 20px

    h2
      margin-bottom: 10px
      color: #000
      font-size: 16px
      font-weight: normal

    ul
      li
        margin-bottom: 5px

        a
          font-weight: 300
