.login, .forgot
  input, label
    line-height: 24px
    font-size: 18px
    font-weight: normal
    margin: 0px
    margin-bottom: 10px

  input[type=email], input[type=password], input[type=text]
    width: 300px
    display: inline-block
    vertical-align: middle
    padding: 5px

  form button
    display: block
    margin-top: 10px
    height: auto

  h2
    font-size: 22px
    font-weight: 300
    margin-bottom: 15px

  .error
    color: red
    margin-bottom: 5px

  .success
    color: #59AA4C
    margin-bottom: 5px

  .forgot
    border-top: solid 1px #BBB
    margin-top: 30px
    padding-top: 30px
