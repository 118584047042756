.dashboard.account.join

  h2
    font-size: 22px
    font-weight: 300
    margin-bottom: 20px

  p
    line-height: 22px

  input
    display: inline-block
    vertical-align: middle
    line-height: 24px
    font-size: 18px
    font-weight: normal
    padding: 5px
    margin: 0px
    width: 250px
    margin-bottom: 5px

  label
    display: inline-block
    width: 120px
    height: 38px
    line-height: 38px
    vertical-align: middle
    margin-bottom: 5px

  .error
    color: red
    display: none

  .cta
    margin-top: 10px
