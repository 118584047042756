.tour

  .ei-file-icon-pdf
    background-image: url("../images/embed/file-types/pdf_24.png")

  h2
    display: inline-block
    vertical-align: middle
    font-size: 20px
    font-weight: normal

  .icon
    margin-left: 8px
    width: 34px
    height: 50px
    display: inline-block
    vertical-align: middle

  .item.photo.tim
    .middle
      background-image: url("../images/home/photos/tim-photo.jpg")
      background-position: center center
      background-repeat: no-repeat

  /*
   * Layout from here:
   * http://stackoverflow.com/questions/10548417/how-to-distribute-floated-elements-evenly-with-a-dynamic-column-and-row-count-in
   */

  .tabs
    height: 120px
    color: #373737

    .block
      text-align: justify
      -ms-text-justify: distribute-all-lines
      text-justify: distribute-all-lines
      font-size: 0

    .stretcher
      width: 100%
      display: inline-block
      font-size: 0
      line-height: 0

    .tab
      width: auto
      display: inline-block
      *display: inline
      zoom: 1
      vertical-align: top
      height: 120px
      opacity: 0.3
      cursor: pointer
      font-size: normal
      -webkit-touch-callout: none
      -webkit-user-select: none
      -khtml-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none

      .icon
        margin-left: 0px
        margin-right: 5px

      h2
        font-size: 18px
        line-height: 120px

      &.left
        text-align: left
      &.right
        text-align: right

      &.selected,
      &:hover
        opacity: 1

    .middle
      display: inline-block
      vertical-align: top

      .tab
        width: 50%

  .lightbox
    width: 100%
    height: 350px
    position: relative
    white-space: nowrap
    overflow: hidden

    .block
      overflow: hidden

    .center
      position: absolute
      left: 50%
      top: 0
      width: 1px
      height: 100%
      overflow: visible

      .arrow-container
        position: absolute
        top: 0px
        height: 100%
        -webkit-touch-callout: none
        -webkit-user-select: none
        -khtml-user-select: none
        -moz-user-select: none
        -ms-user-select: none
        user-select: none

        &.left
          left: -530px
        &.right
          left: 440px

        .spacer
          display: inline-block
          vertical-align: middle
          height: 100%
          width: 0

        .arrow
          display: inline-block
          vertical-align: middle
          width: 80px
          height: 80px
          cursor: pointer

    .container
      position: relative
      left: 0
      top: 0
      height: 100%
      width: 100%
      overflow: visible

    .panel
      width: 100%
      height: 350px
      display: inline-block
      vertical-align: top
      position: relative


      .left
        position: relative
        display: inline-block
        vertical-align: middle

      .right
        position: relative
        display: inline-block
        vertical-align: middle
        width: 500px
        height: 100%

        .paper
          width: 100%
          height: 100%

      h1
        font-family: LeagueGothicRegular
        text-transform: uppercase
        font-size: 80px
        letter-spacing: -2px
        font-weight: normal
        text-align: center
        color: #373737
        text-shadow: 1px 1px 1px rgba(0,0,0,0.2)

        .number
          color: #F9BD00

      .header-wrapper
        text-align: center
        margin-top: 10px

        .cta
          vertical-align: middle

        h2
          margin-right: 10px
          color: #575757

      .bottom-wrapper
        text-align: center
        margin-top: 20px

        *
          display: inline-block
          vertical-align: middle

        .quote
          color: #575757
          text-shadow: none
          margin-top: 35px
          padding-right: 23px

          .author
            color: #878787
            text-align: right
            padding-right: 5px

          .character,
          .end-character
            display: none

        .item
          height: 153px
          width: 400px

          .wrapper
            white-space: normal

      .description
        color: #575757
        font-size: 14px
        line-height: 20px
        padding-left: 25px
        padding-top: 5px
        width: 355px
        white-space: normal
        font-weight: 300

      .press
        width: 330px
        text-align: center
        white-space: normal
        margin-right: auto
        margin-left: auto
        line-height: 70px
        display: inline-block
        vertical-align: middle

        .left
          margin-right: 40px

        *
          display: inline-block
          vertical-align: middle
