.notice
  &.yesno
    .header
      .icon, .text
        display: inline-block
        vertical-align: middle
    .body
      padding: 0px 24px 24px 24px
    .element-container
      text-align: left
    .message
      padding-bottom: 20px
      font-size: 14px
      line-height: 18px
    .yes
      margin-right: 15px
    &.onlyno .yes
      visibility: hidden
    .no
      float: right
    &.onlyyes .no
      visibility: hidden
      display: none
    &.upgrade
      width: 450px
