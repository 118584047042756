.new_password
  input
    width: 300px
    display: inline-block
    vertical-align: middle
    line-height: 24px
    font-size: 18px
    font-weight: normal
    padding: 5px
    margin: 0px
    margin-bottom: 5px

  form
    display: block
    margin-bottom: 10px

  h2
    font-size: 34px
    font-weight: normal
    margin-bottom: 15px

  .cta
    cursor: pointer

  .error
    color: red
    margin-bottom: 5px

    .hidden
      display: none
