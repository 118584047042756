/* CTA buttons. */

a, button
  &.cta
    display: inline-block
    font-size: 15px
    letter-spacing: 0px
    height: 44px
    line-height: 44px
    padding-right: 30px
    padding-left: 30px
    text-shadow: none
    -webkit-border-radius: 5px
    -moz-border-radius: 5px
    -ms-border-radius: 5px
    border-radius: 5px
    font-weight: normal
    text-decoration: none
    cursor: pointer

    /* First we need to help some browsers along for this to work.
     Just because a vendor prefix is there, doesn't mean it will
     work in a browser made by that vendor either, it's just for
     future-proofing purposes I guess. */
    -o-transition: .3s
    -ms-transition: .3s
    -moz-transition: .3s
    -webkit-transition: .3s
    /* ...and now for the proper property */
    transition: .3s

    border-style: solid
    border-width: 3px

    &.blue
      background: #2275C7
      color: #F5F5F1
      border-color: #1C5FA1
      text-shadow: 1px 1px 1px #1C5FA1

      &:hover
        background: #2683DE

    &.gray
      background: #BBB
      color: #373737
      text-shadow: 1px 1px 1px #F5F5F1
      text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5)
      border-color: #888

      &:hover
        background: #DDD

    &.yellow
      background: #F9BD00
      text-shadow: 1px 1px 1px #979797
      color: white
      border-color: orange

      &:hover
        background: #FFC200


    &.green
      background: #52A78E
      color: white
      border-color: #3E7E6B
      text-shadow: 1px 1px 1px #3E7E6B

      &:hover
        background: #60C4A7

    &.white
      background: #F5F5F1
      color: #373737
      border-color: #575757

      &:hover
        background: #FFF

    &.red
      background: #E74C3C
      color: white
      border-color: #C0392B
      text-shadow: 1px 1px 1px #C0392B

      &:hover
        background: #FF5442

    &.small
      padding-right: 20px
      padding-left: 20px
      line-height: 32px
      height: 32px
      border-width: 2px
    &.medium
      padding-right: 20px
      padding-left: 20px
      line-height: 40px
      height: 40px
      border-width: 2px
    &.large
      height: 48px
      line-height: 48px
      font-size: 17px
      border-width: 3px

    &.no-border
      border: none

.cta-container
  margin-top: 10px
  &.inline
    margin: 10px
    display: inline-block
    vertical-align: middle

/* Deprecated version. */

.cta-button
  height: 44px
  cursor: pointer
  &.inline
    display: inline-block
    vertical-align: middle
  a
    display: table
    background: url("../images/embed/controls/button-right.png") center right no-repeat
    padding-right: 16px
    border-spacing: 0px
    /*width: 100%;*/
    margin-right: auto
    margin-left: auto
  &.left a
    margin-left: 0

cta-button a.left
  margin-left: 0

.cta-button
  &.middle a
    text-align: center
    width: 100%
  a
    &:hover
      text-decoration: none
    height: 44px

.cta-button
  span
    display: table-cell
    color: #606060
    /*#404040;*/
    font-size: 20px
    font-weight: normal
    text-shadow: none
    vertical-align: middle
    padding-left: 16px
    margin-right: 16px
    background: url("../images/embed/controls/button-left.png") center left no-repeat
  &.small
    height: 30px
    a
      height: 30px
      color: #404040
      padding-right: 9px
      background: url("../images/embed/controls/button-right-small.png") center right no-repeat
    span
      font-size: 14px
      font-weight: bold
      padding-left: 9px
      margin-right: 9px
      background: url("../images/embed/controls/button-left-small.png") center left no-repeat
  &.medium
    height: 44px
    a
      height: 44px
      color: #404040
      padding-right: 12px
      background: url("../images/embed/controls/button-right-medium.png") center right no-repeat
    span
      font-size: 16px
      font-weight: bold
      padding-left: 12px
      margin-right: 12px
      background: url("../images/embed/controls/button-left-medium.png") center left no-repeat
  span:hover
    color: #000
  &.disabled span:hover
    color: #606060
  &.small.middle
    padding-right: 9px
