@import "_reset"
@import "_fonts"
@import "controls/usericon"
@import "items/item"
@import "items/post_it"
@import "items/photo"
@import "notice"
@import "modals/corksettingsmodal"
@import "modals/yesnomodal"
@import "controls/cta"
@import "home/index"
@import "home/tour"
@import "home/contact"
@import "home/pricing"
@import "home/signup"
@import "home/login"
@import "home/terms"
@import "home/blog/post"
@import "home/dashboard/account"
@import "home/dashboard/profile"
@import "home/dashboard/teams"
@import "home/dashboard/feedback"
@import "home/dashboard/successfully_closed"
@import "home/dashboard/boards"
@import "home/dashboard/new_password"
@import "home/dashboard/account/people"
@import "home/dashboard/account/edit_plan"
@import "home/dashboard/account/settings"
@import "home/dashboard/account/payments"
@import "home/dashboard/account/join"
@import "home/errors/not_found"
@import "ei/cc/large"
@import "ei/loading/all"
@import "ei/modal/general"

/* Overrides. */

.item
  &.post_it
    .data
      font-weight: normal
      white-space: normal
    .shadow
      opacity: 0.3
  .info-message .message
    left: -15px
    margin-top: 3px

  &.photo
    .shadow-left,
    .shadow-right
      opacity: 0.3

/* Common */

html, body
  background: #222423
  width: 100%
  height: 100%
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

.main .block,
.header .block,
.footer .block,
.quotes .block
  width: 900px
  margin-left: auto
  margin-right: auto
  position: relative

.no-user-select
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: -moz-none
  -ms-user-select: none
  -o-user-select: none
  user-select: none
  *
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: -moz-none
    -ms-user-select: none
    -o-user-select: none
    user-select: none

/* Controls */

a, a:visited
  color: #2275c7
  font-weight: normal
  text-decoration: none

.questionmark
  display: inline-block
  width: 30px
  height: 30px
  position: relative
  cursor: pointer
  visibility: hidden

  .popup
    width: 180px
    position: absolute
    left: -85px /* ((width - width of question mark) / 2) - padding-left */
    top: 100%
    padding: 10px 12px 10px 12px
    background: #373737
    font-weight: 300
    font-size: 13px
    line-height: 19px
    text-align: center
    color: #F5F5F1
    z-index: 10
    white-space: normal

    .center
      width: 1px
      height: 100%
      position: absolute
      left: 50%
      top: 0px

      .tail
        border-top: solid 6px transparent
        border-left: solid 6px transparent
        border-right: solid 6px transparent
        border-bottom: solid 6px #373737
        position: absolute
        left: -8px /* half the width */
        top: -12px /* full height */

/* Header */

body > .header
  background: #F5F5F1

  .link
    width: 200px
    line-height: 80px
    display: inline-block
    text-decoration: none

    .paper
      display: inline-block
      width: 26px
      height: 80px
      vertical-align: middle

    .name
      display: inline-block
      font-size: 26px
      line-height: 80px
      color: #878787

  .top
    height: 80px

  .nav
    position: relative
    vertical-align: middle
    line-height: 80px
    margin-top: auto
    margin-bottom: auto
    float: right
    font-size: 15px
    font-weight: normal

    &.left
      float: left

    a
      color: #878787
      text-decoration: none

      &:hover
        color: #373737

      &.cta
        color: white

    li
      display: inline
      padding-left: 15px
      padding-right: 15px
      cursor: pointer
      padding-top: 4px
      padding-bottom: 4px
      border-radius: 2px

  &.account
    background: #1F6AB4

    .top
      height: 50px

    .nav
      right: -15px
      line-height: 50px

      &.left
        right: 0px
        left: -15px

      a
        color: white
        font-weight: 300

        &:hover
          color: #373737

      li.impersonating
        background: white

        &:hover
          background: white

        a
          color: red
          font-weight: 700

          &:hover
            color: orangered

      li
        &:hover,
        &.selected
          background: none
          border-radius: none
          box-shadow: none

        div
          display: inline-block
          vertical-align: middle
          margin-left: 10px



/* Footer */

.footer
  font-size: 12px
  line-height: 30px
  text-shadow: 1px 1px 1px #000
  font-weight: normal

  .trial
    text-align: center
    color: #474747
    position: absolute
    width: 100%
    height: 0px /* To prevent covering up the links */
    left: 0px
    top: 0px

    *
      display: inline-block
      vertical-align: middle
      color: #F5F5F1

    .logo
      width: 30px
      height: 50px
      background: url("../images/home/note.png")
      background-position: center center
      background-repeat: no-repeat
  .free-trial
    font-size: 20px
  .links
    background: #222423
    padding-top: 38px
    height: 170px
    line-height: 48px
    color: #BBB
    font-size: 13px
    .right
      text-align: right
      float: right
    a
      text-decoration: none
      color: #BBB

/* Settings sidebar */

.sidebar-wrapper
  white-space: nowrap
  font-size: 0px

  .sidebar
    display: inline-block
    vertical-align: top
    width: 175px

    a
      display: inline-block
      line-height: 30px
      color: #373737
      text-decoration: none

    .sidebar-item
      border-bottom: solid 1px #DDD
      border-right: solid 1px #DDD
      border-bottom-right-radius: 5px
      margin-bottom: 5px
      overflow: hidden

      .title
        font-size: 18px
        line-height: 40px
        border-right-width: 5px
        border-right-style: solid
        border-right-color: transparent

      .sidebar-subitems
        .sidebar-subitem
          font-size: 15px
          text-align: right
          padding-right: 10px
          cursor: pointer
          border-right-width: 5px
          border-right-style: solid
          border-right-color: transparent

          &.selected,
          &:hover
            /*background: #52A78E*/
            /*color: #F5F5F1 */
            border-right-color: #F9BD00

      &.selected
        .title
          /*border-right-color: #52A78E*/
        .sidebar-subitem
          /*border-right-color: #52A78E*/

  .panel
    display: inline-block
    vertical-align: top
    margin-left: 35px
    white-space: normal
    font-size: 16px
    width: 725px

    h2
      font-weight: 300
      font-size: 22px
      font-weight: normal
      margin-bottom: 20px

    .row
      margin: 10px 0px

    input
      display: inline-block
      vertical-align: middle
      line-height: 24px
      font-size: 18px
      font-weight: normal
      padding: 5px
      margin: 0px

    p
      margin-bottom: 15px

    .error
      color: red
      display: none
      line-height: 22px

      &.success
        color: green


/* Quote blocks. */

.quotes
  padding-top: 50px
  padding-bottom: 50px

.quote
  display: inline-block
  vertical-align: middle
  text-align: right
  text-shadow: 1px 1px 1px #878787
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3)
  color: #F5F5F1
  .character, .end-character
    width: 38px
    height: 38px
    display: inline-block
    vertical-align: middle
  .text
    font-family: LeagueGothicRegular
    text-transform: uppercase
    display: inline-block
    vertical-align: middle
    font-size: 30px
    margin-left: 5px
    margin-right: 5px
    padding-top: 6px
  .author
    display: block
    vertical-align: middle
    font-weight: normal
    text-align: right
    padding-right: 48px


/* Top level colors */

.white-bg
  background: #F5F5F1

  &.padded
    padding-top: 50px
    padding-bottom: 50px

.black-bg
  background: #222423

.yellow-bg
  background: #F9BD00
  color: #F5F5F1

.alert-container
  padding-top: 25px

.alert-warning
  border: 2px solid #F9BD00
  background: adjust-color(#F9BD00, $alpha: -0.8)
  padding: 5px
  border-radius: 5px
  line-height: 1.25

.gray-bg
  background: #BBB

.green-bg
  background: #52A78E

  &.padded
    padding-top: 50px
    padding-bottom: 50px

.dark-blue-bg
  background: #27313E

  &.padded
    padding-top: 50px
    padding-bottom: 50px

/* User icon overrides. Should be removed once user icons are restyled on the main app. */
.user-icon
  -webkit-box-shadow: 0px 1px 1px 1px #3C3C3C
  -moz-box-shadow: 0px 1px 1px 1px #3C3C3C
  -ms-box-shadow: 0px 1px 1px 1px #3C3C3C
  box-shadow: 0px 1px 1px 1px #3C3C3C


/* Press stuff. */

.bbc
  background-image: url("../images/home/press-logos/bbc.png")
  width: 110px
  height: 31px
  margin-right: 40px

.huffpo
  background-image: url("../images/home/press-logos/huffpo.png")
  width: 120px
  height: 46px
  margin-right: 40px

.mashable
  background-image: url("../images/home/press-logos/mashable.png")
  width: 130px
  height: 26px
  margin-right: 40px

.lifehacker
  background-image: url("../images/home/press-logos/lifehacker.png")
  width: 120px
  height: 34px
  margin-right: 40px

.technorati
  background-image: url("../images/home/press-logos/technorati.png")
  width: 130px
  height: 26px
  margin-right: 40px

.hackernews
  background-image: url("../images/home/press-logos/hackernews.png")
  width: 44px
  height: 44px
