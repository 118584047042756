/* Classes shared by items. */

.item {
	position: absolute;
}

.item .move-grip {
	height: 10px;
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	background-repeat: repeat-x;
	opacity: 0.2;
	cursor: move;
	z-index: 1002;
}

.item .ui-resizable-se {
	position: absolute;
	width: 20px;
	height: 20px;
  right: 2px;
  bottom: 2px;
  background: url("../images/items/post-it/resize_se.gif") bottom right no-repeat;
  z-index: 1011;
}

.item .ui-resizable-se:hover {
	cursor: se-resize;
}

.item .info-message {
	position: absolute;
	left: 6px;
	bottom: -12px;
	z-index: 1003;
}

.item .info-message .user-icon {
	display: inline-block;
	vertical-align: middle;
	z-index: 0;
}

.item .info-message .message {
	display: inline-block;
	vertical-align: middle;
	padding-left: 15px;
	padding-right: 5px;
	padding-bottom: 1px;
	position: relative;
	left: -10px;
	color: white;
	color: rgba(255,255,255,0.85);
	font-size: 11px;
	line-height: 18px;
	font-style: italic;
	z-index: -1;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	border-radius: 5px;

	background: #000;
	background: rgba(0,0,0,0.75);

	text-shadow: 2px 2px 2px black;

	border-bottom: solid 1px black;
	border-top: solid 1px #777;
}
