
.item.photo {
  width: 200px;
  height: 250px;
  /*padding: 15px;*/
	border-right: solid 1px rgba(242,242,242,0.5);

	-webkit-box-shadow: 1px 1px 6px rgba(0,0,0,0.4), inset 0px 1px 0px rgba(255,255,255,0.4);
     -moz-box-shadow: 1px 1px 6px rgba(0,0,0,0.4), inset 0px 1px 0px rgba(255,255,255,0.4);
          box-shadow: 1px 1px 6px rgba(0,0,0,0.4), inset 0px 1px 0px rgba(255,255,255,0.4);
}

.item.photo .background-wrapper{
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: #F2F2F2;
}

.item.photo .shadow-left {
  position: absolute;
  left: -14px;
  bottom: -15px;
  width: 169px;
  height: 114px;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.item.photo .shadow-right {
  position: absolute;
  right: -14px;
  bottom: -15px;
  width: 169px;
  height: 114px;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.item.photo .middle {
  position: absolute;
  left: 14px;
  right: 14px;
  top: 14px;
  bottom: 48px;
  z-index: 1002;
  border: solid 1px #AAAAAA;
	background: #000;
}

.item.photo .middle .loading {
	height: 100%;
	width: 100%;
	background: url("../images/items/photo/loading.gif") center center no-repeat;
}

.item.photo .middle .broken {
	font-size: 12px;
    text-shadow: 0 1px 0 #000;
    color: #FFFFFF;

    /* Fallback for web browsers that doesn't support RGBa */
    background: #000000;
}

.item.photo .middle .image {
	width: 100%;
	height: 100%;
}

.item.photo .middle div.image {
	display: table;
}

.item.photo .middle .image .broken {
    display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.item.photo .ui-resizable-e, .item.photo .ui-resizable-s {
    display: none !important;
}

.item.photo .ui-resizable-se {
    right: 2px;
    bottom: 2px;
    z-index: 1002 !important;
}

.item.photo .wrapper {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 40px;
    z-index: 1010;
}

.item.photo .caption {
    width: 100%;
    height: 100%;

    border: none;
    padding: 0px;
    margin: 0px;
    background: transparent;

    /* Remove browser ideosyncrasies. */
    outline: none;
    resize: none;

    font-weight: normal;

    z-index: 1010;
}

