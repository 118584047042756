.dashboard.account.edit_plan

  .plans
    margin-top: 15px
    margin-bottom: 15px

    .plan
      width: 450px
      padding: 10px
      margin-bottom: 5px
      -webkit-border-radius: 5px
      -moz-border-radius: 5px
      -ms-border-radius: 5px
      border-radius: 5px
      cursor: pointer

      input
        margin-left: 10px
        margin-right: 10px

      .extra
        color: #BBB
        text-shadow: 1px 1px 1px #FFF

        &.sale
          color: #59AA4C

      &.selected,
      &:hover
        background: #F9BD00

        .extra
          text-shadow: 1px 1px 1px rgba(255,255,255,0.4)
          color: #777

  .info
    margin-bottom: 15px
    line-height: 22px

    &.error
      color: red

  #change_plan
    cursor: pointer
