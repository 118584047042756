.terms, .privacy, .about
  .main
    line-height: 18px
    font-size: 14px
    color: #444
    text-shadow: 1px 1px 1px white

    ul
      list-style-type: square

    ol
      list-style-type: decimal

    p,ul,ol
      margin-bottom: 25px

    li
      margin-bottom: 10px
      margin-left: 25px

    h1
      text-align: left
      font-family: LeagueGothicRegular
      font-size: 60px
      margin-bottom: 35px
      margin-top: 30px

    h2
      margin-bottom: 10px
      font-weight: normal
      color: black
