.dashboard.account

  .panel
    ul
      list-style-type: disc
      padding-top: 15px
      padding-bottom: 15px
      padding-left: 20px

      li
        line-height: 22px

    .description
      padding-bottom: 15px


    #cancel,
    .description
      display: none
