.home.contact
  .input-wrapper, textarea
    display: inline-block
    vertical-align: middle
    line-height: 24px
    font-size: 18px
    font-weight: normal
    padding: 5px
    margin: 0px
    margin-bottom: 5px
    border: solid 1px #BBB

  .input-wrapper
    background: #FFF
    width: auto
    white-space: nowrap
    color: #AAA
    input
      width: 250px

      font-weight: normal
      line-height: 24px
      font-size: 18px


  input
    display: inline-block
    border: none
    background: transparent

  textarea
    width: 600px
    height: 130px

  form
    display: block
    margin-bottom: 10px

  h2
    font-size: 22px
    font-weight: 300
    margin-bottom: 15px

  .error
    color: red
    margin-top: 5px
    margin-bottom: 5px

    &.success
      color: green
