/* Recurly */
/* .ei-***-large where *** is the cc type code */

.ei-visa-large
  background-image: url("../images/recurly/visa.gif")

.ei-mastercard-large
  background-image: url("../images/recurly/master.gif")

.ei-american_express-large
  background-image: url("../images/recurly/american_express.gif")

.ei-discover-large
  background-image: url("../images/recurly/discover.gif")
