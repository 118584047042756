.feedback
  h2
    font-size: 34px
    font-weight: normal
    span
      color: #BBB
  textarea
    margin-top: 10px
    margin-bottom: 10px
    width: 100%
    height: 80px
