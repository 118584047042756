@mixin keyframes
  @-webkit-keyframes blinkborder
    @content

  @-moz-keyframes blinkborder
    @content

  @keyframes blinkborder
    @content

@include keyframes
  from
    border: solid 1px #CCC

  to
    border: solid 1px #F5F5F1

.profile

  .picture
    border: solid 1px #DDD
    width: 120px
    height: 120px
    background: url("../images/home/wink.png") center center no-repeat
    display: inline-block
    vertical-align: bottom
    -webkit-border-radius: 50%
    -moz-border-radius: 50%
    -ms-border-radius: 50%
    border-radius: 50%
    overflow: hidden
    margin-right: 30px

    &.uploading
      -webkit-animation: blinkborder 0.75s linear infinite alternate
      -moz-animation: blinkborder 0.75s linear infinite alternate
      -ms-animation: blinkborder 0.75s linear infinite alternate
      -o-animation: blinkborder 0.75s linear infinite alternate
      animation: blinkborder 0.75s linear infinite alternate

    > img
      display: block
      height: 100%
      width: 100%
      border: 0

  #edit_profile
    width: 80px
    text-align: center
    display: inline-block
    vertical-align: bottom

  #upload_photo
    position: relative

    input
      position: absolute
      left: 0px
      top: 0px
      height: 100%
      width: 100%
      opacity: 0
      -moz-opacity: 0
      filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0)
      cursor: pointer
