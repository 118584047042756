.boards
  .block
    &.list
      font-size: 0px

    &.support
      border-top: solid 1px #BBB
      margin-top: 30px
      padding-top: 30px


  .account-wrapper
    margin-bottom: 40px
    position: relative

    .account-title
      border-bottom: solid 1px #DDD
      margin-bottom: 15px

      .right-wrapper
        display: inline-block
        vertical-align: middle
        position: relative
        float: right

        .cta
          display: inline-block
          vertical-align: middle
          margin-left: 6px
          line-height: 26px
          height: 26px
          font-size: 14px
          padding-top: 3px


          .paper
            display: inline-block
            height: 100%
            width: 32px

        .input-wrapper
          display: inline-block
          vertical-align: middle
          position: relative

          input
            width: 200px
            font-size: 16px
            font-weight: 300
            padding: 4px
            border: solid 1px #DDD
            margin: 0px

        .enter-hint
          font-weight: 300
          -moz-border-radius-bottomright: 5px
          -moz-border-radius-topright: 5px
          -webkit-border-bottom-right-radius: 5px
          -webkit-border-bottom-left-radius: 5px
          border-bottom-right-radius: 5px
          border-bottom-left-radius: 5px
          right: 0
          padding-right: 8px
          padding-left: 8px
          color: #F5F5F1
          line-height: 23px
          font-size: 12px
          position: absolute
          background: #373737
          z-index: 10


      h2
        font-size: 22px
        font-weight: normal
        font-weight: 300
        display: inline-block
        vertical-align: middle
        line-height: 36px


    .board-wrapper
      position: relative
      min-height: 90px

      .nothing-to-see-here, .account-blocked, .chugging
        height: 90px
        width: 100%
        position: absolute
        left: 0px
        top: 0px
        line-height: 90px
        font-size: 16px
        text-align: center
        color: #AAA
        text-shadow: 1px 1px 1px white
        z-index: 1

        .add_board_in_text
          color: #AAA
          text-decoration: underline
          cursor: pointer
          font-weight: 300

          &:hover
            color: #373737

      .account-blocked
        height: auto
        color: darkred
        font-weight: 600
        line-height: 1.5
        max-width: 600px
        margin: auto
        position: relative
        padding: 8px 0px 24px

        a
          font-weight: 600

        .support-notice
          margin-top: 8px
          font-weight: 300
          font-size: 12px

      &.blocked .board
        pointer-events: none !important
        opacity: 0.5 !important

  .board
    position: relative
    background: #F5F5F1
    width: 100%
    height: 90px
    display: block
    margin-bottom: 15px
    vertical-align: top
    margin-right: 6px
    -webkit-border-radius: 3px
    -moz-border-radius: 3px
    -ms-border-radius: 3px
    border-radius: 3px
    z-index: 2

    .panel
      position: absolute
      top: 0px
      left: 144px
      right: 0px
      height: 100%
      background: #F5F5F1
      -webkit-border-radius: 3px
      -moz-border-radius: 3px
      -ms-border-radius: 3px
      border-bottom: solid 1px #E0E0E0
      margin-left: 10px
      z-index: 3

      .name
        font-weight: 300
        font-size: 16px
        color: #373737
        margin: 0px
        width: 300px

        &:hover
          cursor: inherit

        &.disabled
          padding: 0px
          background: transparent
          border: 0

          &:hover
            cursor: pointer

      .timeago
        font-size: 12px
        float: right
        font-weight: normal

      .link
        font-size: 11px
        line-height: 18px


      .controls
        position: absolute
        bottom: 0px
        left: 0px
        width: 100%
        height: 36px

        .press-enter
          height: 36px
          line-height: 36px
          display: inline-block
          vertical-align: middle
          font-size: 14px
          color: #AAA

        .button
          width: 36px
          height: 36px
          display: inline-block
          vertical-align: middle
          cursor: pointer
          border-bottom: solid 3px transparent

          &.copy:hover, &.export:hover, &.settings:hover, &.edit-name:hover
            border-bottom: solid 3px #52a78e

          &.delete:hover
            border-bottom: solid 3px #E74C3C

          &.delete
            float: right

            &.loading
              background: url("../images/embed/modals/loading-small.gif") center center no-repeat

    .background
      display: inline-block
      width: 144px
      height: 90px
      background-position: center center
      background-repeat: no-repeat
      background-image: url("../images/embed/modals/loading-small.gif")
      -webkit-border-radius: 3px
      -moz-border-radius: 3px
      -ms-border-radius: 3px
      border-radius: 3px
      cursor: pointer
      z-index: 2

    img
      width: 144px
      height: 90px
      -webkit-border-radius: 3px
      -moz-border-radius: 3px
      -ms-border-radius: 3px
      border-radius: 3px

    a
      text-decoration: none
