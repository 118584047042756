.notice
  &.cork-settings
    .body
      width: 543px
      padding: 0px 23px 23px 23px
      text-align: left

      .theme
        /*height: 300px*/

        .top-container
          width: 100%

        .theme-item
          width: 80px
          height: 80px
          display: inline-block
          background-position: center center
          background-repeat: no-repeat
          cursor: pointer
          text-align: center
          line-height: 80px

        .backgrounds-container
          width: 100%
          text-align: justify
          font-size: 0

          div
            margin-right: 12px

            &:last-child
              margin-right: 0px

          img
            width: 100%
            height: 100%

        .or-separator
          position: relative
          width: 100%
          vertical-align: top
          text-align: center
          margin-top: 15px
          padding-top: 15px
          border-top: solid 1px #CCC

        .custom-container
          position: relative

          .preview
            border: solid 1px #DDD
            color: #DDD

            img
              width: 100%
              height: 100%
              border-radius: 4px

          .upload-wrapper
            position: absolute
            top: 0px
            right: 0px
            float: right

          input[type=file]
            position: absolute
            top: 0px
            left: 0px
            height: 100%
            width: 100%
            opacity: 0
            -moz-opacity: 0
            filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0)
            cursor: pointer

          input[type=text]
            width: 150px
            display: inline-block
            vertical-align: bottom
            line-height: 20px
            font-size: 18px
            padding: 5px
            margin: 0px
            margin-left: 5px
            color: #878787
            border-bottom: solid 1px #878787

          .color-button
            position: absolute
            right: 0px
            bottom: 0px

      .url
        font-size: 18px
        width: 520px
        margin-top: 5px

    h2
      margin-bottom: 5px

      a
        color: #0064BF
    .urls
      span
        display: block
        margin-bottom: 5px
      textarea
        &.embed
          width: 100%
          height: 40px
          margin-bottom: 25px
        &.view-only
          width: 100%
          height: 15px
          margin-bottom: 5px
    .accessibility-dropdown
      width: 335px
      display: inline-block
    .password
      margin-left: 7px
      font-size: 13px
      vertical-align: bottom
    .cta-button.left
      position: absolute
      left: 23px
      bottom: 23px
    .access-select
      width: 225px
      font-size:  16px

    .pane-wrapper
      width: 520px

      .access-description
        margin-bottom: 10px
        line-height: 18px

    .right-pane
      float: right
    .left-pane, .right-pane
      width: 250px
      display: inline-block
      vertical-align: top
    .selection-div
      border: solid 1px transparent
      cursor: pointer
      height: 30px
      line-height: 16px
      padding-left: 5px
      padding-right: 5px
      *
        vertical-align: middle
        display: inline-block
      input[type="checkbox"]
        cursor: pointer
      /* Is radio used here anymore? */
      input[type="radio"]
        cursor: pointer
        position: relative
        top: -2px
      &:hover
        color: white
        text-shadow: none
        background: #373737
      &.disabled
        color: #AAA

    .selection-image
      display: inline-block
      vertical-align: middle
      width: 40px
      height: 30px
      background-position: center center
      background-repeat: no-repeat
      &.none
        width: 7px
