/*
 * Disable selection on transparent pieces while notice is shown.
 * This prevents the user from highlighting the whole application.
 */

.notice
  &.outer, &.inner, &.horizontal-center
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: -moz-none
    -o-user-select: none
    user-select: none

.bar, .board .drag-window
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: -moz-none
  -o-user-select: none
  user-select: none

/* Ensure that doesn't propagate up to the portion interacted with.*/

.notice
  &.block
    -webkit-user-select: text
    -khtml-user-select: text
    -moz-user-select: text
    -o-user-select: text
    user-select: text
  &.outer
    position: fixed
    left: 0px
    top: 0px
    display: table
    width: 100%
    height: 100%
    z-index: 200000
    /* Make sure notices are well above the bar.*/
    background: rgba(0, 0, 0, 0.5)
    &.no-shadow
      background: none
  &.inner
    display: table-cell
    vertical-align: middle
  &.horizontal-center
    width: 100%
    margin-left: auto
    margin-right: auto
  &.block
    position: relative
    height: 100%
    display: table
    margin-left: auto
    margin-right: auto
    overflow: visible
    /* One value above the bottom toolbar.*/
    z-index: 100001
    .header-wrapper
      width: 100%
      background: #000
      padding-top: 13px
      padding-bottom: 13px
      .header
        font-size: 18px
        line-height: 24px
        font-weight: bold
        text-shadow: 2px 2px 2px black
        color: white
        margin-left: 23px
        display: inline
        vertical-align: middle
        .text
          vertical-align: middle
          display: inline-block
      .tab-wrapper
        display: inline-table
        border-spacing: 5px 0px
        margin-left: 23px
        margin-right: 20px
        vertical-align: middle
        .tab
          display: table-cell
          font-weight: bold
          font-size: 12px
          padding: 3px 4px 3px 4px
          background: #000
          color: white
          &:hover
            background: #333
            color: white
            cursor: pointer
          &.selected
            background: white !important
            color: #000 !important
    .footer-wrapper
      width: 100%
      background: #000
      background: rgba(0, 0, 0, 0.9)
      padding-top: 13px
      padding-bottom: 13px
      .footer
        font-size: 13px
        font-weight: normal
        text-shadow: 2px 2px 2px black
        color: white
        padding-left: 20px
        display: inline
        vertical-align: middle
    .close
      position: relative
      float: right
      top: -15px
      right: -15px
      width: 40px
      height: 40px
      cursor: pointer
      z-index: 100002
  &.body
    position: relative
    text-align: center
    color: #FFFFFF
    font-size: 13px
    text-shadow: 2px 2px 2px #000
    padding: 33px
    overflow: hidden
  .background-rgba-fix
    background: rgb(0, 0, 0)
    background: rgba(0, 0, 0, 0.8)
  &.initialize .block .background-rgba-fix
    display: none
  &.noscript
    width: 450px
  &.loading .body
    width: 32px
    height: 32px
    background-position: center center
    background-repeat: no-repeat
    margin-left: auto
    margin-right: auto
  &.body
    .element-container
      width: 100%
      height: 100%
    h1
      font-size: 20px
      font-weight: normal
      text-shadow: 0 2px 0 #000
    a
      color: #888
  label
    margin-top: 14px
    margin-bottom: 14px
    width: 120px
    display: inline-block
    font-size: 14px
    font-weight: normal
    text-shadow: 1px 1px 1px black
    &.top
      vertical-align: top
  input
    &[type=text], &[type=password]
      background: none
      border-top: none
      border-right: none
      border-left: none
      border-bottom: dotted 1px white
      color: white
      font-weight: normal
      text-shadow: 2px 2px 2px #000
  .icon
    margin-right: 5px
    width: 30px
    height: 24px
    display: inline-block
    vertical-align: middle
    background-position: center center
    background-repeat: no-repeat
  .hint-div
    position: relative
    .hint-text
      display: inline-block
      line-height: 16px
      font-size: 13px
      color: #AAA
  &.white
    background: #F5F5F1
    &.block
      box-shadow: 0px 0px 10px -3px black
    .body
      color: #373737
      text-shadow: 1px 1px 1px #FFF
      h1
        text-shadow: none
      .background-rgba-fix
        background: none
    .header-wrapper
      background: none
      .header
        color: #373737
        text-shadow: 1px 1px 1px white
        font-weight: normal
      .tab-wrapper .tab
        background: none
        color: #373737
        padding: 7px
        font-weight: normal
        &.selected
          background: #373737 !important
          color: white !important
    label
      text-shadow: inherit
    input
      &[type=text], &[type=password]
        border-bottom: solid 1px #373737
        color: #373737
        text-shadow: inherit
    .message a
      color: #0064BF
      text-decoration: underline
    .hint-div .hint-text
      a
        color: #0064BF
        text-decoration: underline
      color: #373737
