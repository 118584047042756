.dashboard.account.payments

  .card-wrapper
    display: table
  .card-image
    width: 54px
    height: 38px
    background-position: center center
    background-repeat: no-repeat
    -webkit-border-radius: 3px
    -moz-border-radius: 3px
    -ms-border-radius: 3px
    border-radius: 3px
    display: table-cell
    -webkit-box-shadow: 0px 0px 15px -5px black
    -moz-box-shadow: 0px 0px 15px -5px black
    -ms-box-shadow: 0px 0px 15px -5px black
    box-shadow: 0px 0px 15px -5px black
  .card-number
    display: table-cell
    vertical-align: middle
    padding-left: 10px
  .card-image.jcb
    width: 30px
    margin-left: 10px
    margin-right: 10px
  .expires-message
    color: #D00


  .row
    margin-bottom: 3px
    font-size: 0px

    label:not(.radio)
      width: 125px
      font-size: 16px
      display: inline-block
      vertical-align: middle
      margin-left: 20px
      color: #575757
      text-shadow: 1px 1px 1px white
      font-weight: 300

    input
      width: 300px
      display: inline-block
      vertical-align: middle
      line-height: 24px
      font-size: 18px
      font-weight: normal
      padding: 5px
      margin: 0px

    .form-input-wrapper
      position: relative
      background: #FFF
      border: solid 1px #B7B7B7
      display: inline-block
      vertical-align: middle
      overflow: hidden
      padding: 5px

      &.number
        width: 300px

      &.month
        width: 142px
        margin-right: 3px

      &.year
        width: 143px

      &.cvv
        width: 100px

      .icon
        width: 40px
        height: 100%
        position: absolute
        right: 0px
        top: 0px
        z-index: 0

      .recurly-hosted-field
        height: 30px
