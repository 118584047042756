.dashboard.teams

  .team-wrapper
    margin-bottom: 35px

    .row
      padding-bottom: 5px
      margin-bottom: 5px
      border-bottom: solid 1px #DDD

      div
        display: inline-block
        vertical-align: middle

      .name
        width: 475px
        overflow: hidden
        white-space: no-wrap

      .role
        width: 115px

    .cta
      font-size: 16px
      font-weight: 300
      display: inline-block
      vertical-align: middle
