.signup

  .block
    &.hidden
      display: none

    .inner
      border-bottom: solid 1px #DDD

    &.box
      padding-top: 30px

      .inner
        padding-bottom: 30px

      &.first
        .inner
          padding-bottom: 10px
          border-bottom: none

      &.last
        border-bottom: none

        .inner
          padding-bottom: 0px
          border-bottom: none

      &.submit
        padding-top: 0px
        border-top: none

      &.no-padding
        padding-top: 0px

        .inner
          padding-bottom: 0px


  .savings
    color: #474747
    font-weight: normal

  .strike
    text-decoration: line-through
    color: #CCC

  .main
    padding-bottom: 100px

    .block
      width: 800px

      &.receipt
        background: #FFF
        border-left: solid 1px #BBB
        border-right: solid 1px #BBB

        .inner
          padding-left: 20px
          padding-right: 20px
          padding-bottom: 35px


        li
          padding-left: 20px

    h1
      font-weight: 300
      text-transform: uppercase
      font-size: 34px
      text-align: left
      color: #373737
      text-shadow: 1px 1px 1px #FFF

      .type
        text-transform: none
        color: #878787
        font-size: 15px

      .number
        color: #F9BD00

    h2
      font-weight: 300
      font-size: 16px
      text-align: left
      color: black
      text-shadow: none
      margin-bottom: 10px

      .questionmark
        display: inline-block
        vertical-align: middle

        .popup
          width: 300px
          left: -146px

    p.confirmation-required
      line-height: 1.25
      margin-bottom: 10px

    #payment
      h2
        height: 30px
        line-height: 30px

    ul
      font-size: 16px
      margin-right: 0px

      li
        padding-left: 20px
        padding-top: 15px
        color: #878787

        .left
          display: inline-block
          width: 175px

        .right
          display: inline-block
          text-align: right
          font-weight: 300
          width: 263px

          &.billing
            text-decoration: underline

      &.inline
        display: inline-block

      &.features
        margin-bottom: 20px

    .row
      margin-bottom: 3px
      font-size: 0px

      &.errors
        display: none
        font-size: 14px
        color: red
        padding-top: 10px
        padding-left: 20px
        width: 500px

      &.please-note
        /*background: #F9BD00*/
        /*border: solid 1px orange*/
        font-size: 14px
        font-weight: 300
        color: #878787
        width: 417px
        margin-left: 20px
        margin-top: 20px
        /*padding: 10px*/
        /*-webkit-border-radius: 5px*/
        /*-moz-border-radius: 5px*/
        /*-ms-border-radius: 5px*/
        /*border-radius: 5px*/

        ul
          font-size: 14px
          list-style-type: disc
          list-style-position: outside
          padding-left: 20px

          li
            font-size: 14px
            padding-left: 0px

    a
      margin-top: 15px

    label:not(.radio)
      width: 125px
      font-size: 16px
      display: inline-block
      vertical-align: middle
      margin-left: 20px
      color: #575757
      text-shadow: 1px 1px 1px white
      font-weight: 300

    input
      border: solid 1px #B7B7B7
      width: 300px
      display: inline-block
      vertical-align: middle
      line-height: 24px
      font-size: 18px
      font-weight: normal
      padding: 5px
      margin: 0px

    input[type=radio]
      width: auto
      vertical-align: top
      margin-right: 5px

    select
      width: 183px
      height: 100%
      line-height: 24px
      font-size: 18px
      font-weight: normal
      margin: 0px
      -webkit-appearance: none
      -webkit-border-radius: 0px
      -moz-border-radius: 0px
      -ms-border-radius: 0px
      border-radius: 0px
      background: transparent
      border: solid 1px transparent
      cursor: pointer
      position: relative
      z-index: 1

    .form-input-wrapper
      position: relative
      background: #FFF
      border: solid 1px #B7B7B7
      display: inline-block
      vertical-align: middle
      overflow: hidden
      padding: 5px

      &.number
        width: 300px

      &.month
        width: 142px
        margin-right: 3px

      &.year
        width: 143px

      &.cvv
        width: 100px

      .icon
        width: 40px
        height: 100%
        position: absolute
        right: 0px
        top: 0px
        z-index: 0

      .recurly-hosted-field
        height: 30px

    .cvv-icon
      height: 28px
      width: 45px
      background-image: url("../images/home/cvv.png")
      background-position: center center
      background-repeat: no-repeat
      vertical-align: middle
      display: inline-block


    .lock-wrapper
      float: right
      height: 30px
      line-height: 30px
      font-size: 14px
      margin-right: 20px
      color: #FFFFFF
      background: #BBB
      padding-left: 4px
      padding-right: 7px
      -webkit-border-radius: 5px
      -moz-border-radius: 5px
      -ms-border-radius: 5px
      border-radius: 5px

      .lock
        display: inline-block
        vertical-align: top
        width: 20px
        height: 30px

  .success
    display: none
    position: fixed
    left: 0px
    right: 0px
    top: 0px
    bottom: 0px
    background: #373737
    background: rgba(0,0,0,0.5)
    font-weight: 300
    z-index: 2

    .table
      display: table
      height: 100%
      width: 100%

      .cell
        display: table-cell
        vertical-align: middle
        width: 100%

        .modal
          font-weight: normal
          line-height: 22px
          width: 580px
          background: #F5F5F1
          border-radius: 15px
          padding: 30px
          margin-left: auto
          margin-right: auto
          -webkit-box-shadow: 0px 0px 15px #373737
          -moz-box-shadow: 0px 0px 15px #373737
          -ms-box-shadow: 0px 0px 15px #373737
          box-shadow: 0px 0px 15px #373737

          h3
            font-size: 30px
            font-family: LeagueGothicRegular
            font-weight: normal
            text-transform: uppercase
