.user-icon {
	width: 24px;
	height: 24px;

	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	display: inline-block;
	vertical-align: middle;

	-webkit-box-shadow: 0px 1px 1px 1px #222;
	-moz-box-shadow: 0px 1px 1px 1px #222;
	-ms-box-shadow: 0px 1px 1px 1px #222;
	box-shadow: 0px 1px 1px 1px #222;
}

.user-icon img {
  vertical-align: top;

	width: 100%;
	height: 100%;

	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	/*-webkit-box-shadow: 0px 2px 5px black;
	-moz-box-shadow: 0px 2px 5px black;
	box-shadow: 0px 2px 5px black;*/
}

.user-icon.guest {
	background: #999;
}

.user-icon.current {
	background: #3B6199;
}
