.home.errors.not_found
  h1
    font-size: 30px
    font-weight: 300
    margin-bottom: 10px

  .lone-ranger-wrapper
    width: 300px
    height: 250px
    margin-top: 20px
    margin-bottom: 20px
    overflow: hidden
    border-radius: 5px
    border: solid 1px #DDD

    .lone-ranger
      width: 300px
