.pricing

  .press
    height: 120px

    .block
      height: 100%

      *
        display: inline-block
        vertical-align: middle
        background-position: center center
        background-repeat: no-repeat

  .main
    padding-bottom: 0px

    h1
      text-transform: uppercase
      font-size: 35px
      font-weight: 300
      text-align: center
      color: #373737
      margin-bottom: 15px

      .number
        color: #F9BD00

      .cap
        width: 55px
        height: 45px
        display: inline-block
        vertical-align: middle
        margin-right: 10px

    h2
      font-size: 14px
      font-weight: normal
      text-align: center
      color: #878787
      text-shadow: none
      margin-bottom: 50px
      text-shadow: 1px 1px 1px #FFF

    .upgrade-banner
      text-align: center
      margin-bottom: 25px
      padding: 10px
      background: #FFF
      border-bottom: solid 1px orange
      display: block
      color: #575757

    .plans
      white-space: nowrap
      font-size: 0px
      padding-bottom: 50px

      *
        vertical-align: top

      .row
        /*background: #EEE*/
        font-size: 14px
        line-height: 30px
        color: #575757
        border-top: solid 1px #FFF
        border-bottom: solid 1px #DDD
        font-weight: normal

        &.top
          height: 70px
          border-top: none
          text-align: center
          font-size: 44px
          font-family: LeagueGothicRegular
          text-transform: uppercase
          line-height: 82px /* Why isn't this centering correctly? Should be 70px' */
          background: none

        &.bottom
          border-bottom: none
          background: none
          padding-top: 15px
          padding-bottom: 15px

        .checkmark
          display: inline-block
          width: 30px
          height: 30px
          position: relative


      .description
        display: inline-block
        width: 340px

        .row
          padding-left: 5px

      .plan
        width: 180px
        display: inline-block
        position: relative
        z-index: 0

        .or
          color: #AAA
          text-shadow: none

        .sale
          position: absolute
          left: 0px
          top: 0px
          width: 100%
          height: 100%

        .row
          text-align: center

        &.spacer
          width: 10px

          &.last
            width: 100px

        &.highlighted
          position: relative
          background: #FFF
          -webkit-border-radius: 5px
          -moz-border-radius: 5px
          -ms-border-radius: 5px
          border-radius: 5px
          z-index: 1

          .row
            border-bottom: solid 1px #EEE
            background: none

            &.top
              border-top: none
            &.bottom
              border-bottom: none

  .other-pricing

    .cap
      height: 45px
      margin-bottom: 10px

    .container
      font-size: 15px
      text-shadow: none
      border-radius: 5px
      padding: 10px
      cursor: pointer
      border: solid 2px #373737
      text-align: center
      margin-left: auto
      margin-right: auto
      position: relative
      width: 250px

      a
        text-decoration: none
        color: #373737
        font-style: normal

      &:hover
        background: #373737
        color: #FFF

        a
          color: #FFF

  .q-and-a
    font-size: 0px

    .block
      border-top: solid 1px #DDD
      border-bottom: solid 1px #FFF

      .inner
        padding-top: 30px
        padding-bottom: 30px
        border-top: solid 1px #FFF
        border-bottom: solid 1px #DDD

    .set
      display: inline-block
      width: 280px
      vertical-align: top
      font-size: 13px
      font-weight: 300
      line-height: 18px
      color: #878787
      text-shadow: 1px 1px 1px white
      padding-right: 30px

      &.last
        padding-right: 0px

      .question
        font-weight: normal
        color: #373737
        padding-bottom: 10px
