/* Index */
.index

  h1
    text-align: center
    padding-top: 5px
    font-weight: 300
    font-size: 46px
    line-height: 50px
    margin-bottom: 24px

  h2
    text-align: center
    color: #AAA
    font-weight: 300
    font-size: 18px
    margin-bottom: 34px

  h3
    width: 100%
    font-weight: 300
    font-size: 26px
    line-height: 200px
    color: white
    text-align: center

  h4
    font-weight: 300
    font-size: 18px
    color: white

  .spread
    color: #373737

    .block
      height: 100%

      .top
        left: 0
        top: 0
        width: 100%
        height: 100%
        text-align: center
        z-index: 2
        padding-top: 60px
        padding-bottom: 150px

    .cta.trial
      vertical-align: middle

    .take-a-tour
      display: inline-block
      vertical-align: middle
      color: #F5F5F1
      line-height: 18px
      font-size: 15px

      .or
        padding-left: 20px
        padding-right: 20px

      .tour
        color: #F5F5F1
        text-decoration: underline
        &:hover
          text-decoration: none

  .press
    height: 120px

    .block
      height: 100%

      *
        display: inline-block
        vertical-align: middle
        background-position: center center
        background-repeat: no-repeat

  .interface
    border-top: solid 8px #3E7E6B
    padding-bottom: 50px
    text-align: center

    .panels
      width: 90%
      height: 300px
      border-radius: 5px
      background-size: 50%
      margin-left: auto
      margin-right: auto
      background: #3E7E6B
      color: white
      overflow: hidden
      position: relative

      .video
        width: 100%
        height: 100%
        position: absolute
        left: 0
        top: 0
        font-size: 20px
        line-height: 300px
        font-weight: 300

        .play
          width: 100%
          height: 100%
          position: absolute
          top: 0
          left: 0
          cursor: pointer

          svg
            vertical-align: top
            cursor: pointer

        .replay
          height: 50px
          width: 50px
          position: absolute
          left: 0px
          bottom: 0px
          font-size: 15px
          line-height: 50px
          font-weight: 300
          cursor: pointer
          display: none

        .note
          width: 100%
          height: 100%
          position: absolute
          left: 0
          top: 0
          font-size: 12px
          line-height: 5px
          font-weight: 300
          color: #373737
          text-align: left
          display: none

          .edit
            position: absolute
            left: 371px
            top: 111px
            display: none

        .annotation
          position: absolute
          text-align: right
          font-size: 14px
          line-height: 20px
          font-weight: 300
          padding: 5px 10px 5px 20px
          background: #373737
          border-radius: 5px
          left: 570px
          top: 207px
          white-space: nowrap
          display: none

        .person
          position: absolute
          width: 70px
          height: 70px
          border: solid 6px #F5F5F1
          border-radius: 50%
          background: #F5F5F1
          overflow: hidden
          display: none

          &.tim
            left: 500px
            top: 110px

          &.chris
            left: 590px
            top: 110px

          &.bridgette
            left: 680px
            top: 110px

          img
            width: 100%
            height: 100%
            vertical-align: top

        .text, .done
          text-align: center
          position: absolute
          left: 0px
          top: 0px
          width: 100%
          height: 100%
          display: none


  .snippets

    .block
      padding-left: 20px

    .sections
      .section
        display: inline-block
        vertical-align: top
        margin-right: 5px
        width: 293px
        position: relative
        color: white

        &.three
          margin-right: 0px

        h4
          margin-bottom: 30px

        div
          font-size: 16px
          line-height: 22px
          font-weight: 300
          color: #373737

  .highlighted-quote
    text-align: center
    padding-top: 100px
    padding-bottom: 100px

    .jason
      width: 90px
      height: 90px
      border: solid 8px #F5F5F1
      background: #F5F5F1
      border-radius: 50%
      overflow: hidden
      display: inline-block
      vertical-align: middle

      img
        width: 100%
        height: 100%
